
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import useTranslation from 'next-translate/useTranslation';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import getConfig from 'next/config';
import { MovieDb, WatchProvider } from 'moviedb-promise';
import Media from '../components/cards/Media';
import { format, formatRelative } from 'date-fns';
const { publicRuntimeConfig } = getConfig();
import Utils, { capitalizeFirstLetter, capitalizeWords } from '../utils/Utils';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
function getCardList({ items }) {
    return (<Row xs={2} sm={3} md={3} lg={4} xl={6} xxl={12} className="g-4">
                {items.map((item, idx) => (<Col key={idx}>
                            <Media url={Utils.getEntityUrl(item)} imageUrl={Utils.getEntityImageUrl(item)} title={Utils.getEntityTitle(item)} dateString={Utils.getEntityDateString(item)} 
        // imageLoadingPriority={true}
        imageLoading={'lazy'} quality={40} unoptimized={true}/>
                        </Col>))}
            </Row>);
}
// export default function Home({ watchProviders, items1, items2, items3, items4, generationDate }: InferGetStaticPropsType<typeof getStaticProps>) {
export default function Home({ watchProviders, items1, items2, items3, items4, generationDate }: PageProps) {
    const { t } = useTranslation('common');
    watchProviders = Utils.getCurrentLocaleWatchProviders(watchProviders);
    watchProviders = Utils.orderWatchProvidersByDisplayPriority(watchProviders);
    return (<Container>

                {watchProviders.length > 0 &&
            <Row xs={2} sm={3} md={3} lg={4} xl={6} xxl={12} className="g-4">
                            {watchProviders.map((item, idx) => (<Col key={idx}>
                                        <img src={Utils.getWatchProviderImageUrl(item, 2)} className="rounded mx-auto d-block" alt="..."/>
                                    </Col>))}
                        </Row>}

                {/*
    <CardGroup>
        {movies.map((movie, idx) => (
            <Card key={idx}>
                <Card.Img variant="top" src={movieDbConfig.images.secure_base_url + movieDbConfig.images.poster_sizes[6] + movie.poster_path}/>
                <Card.Body>
                    <Card.Title>{movie.title}</Card.Title>
                </Card.Body>
                <Card.Footer>
                    <small className="text-dark">{DateTime.fromISO(movie.release_date).toLocaleString({year: 'numeric', month: 'short', day: 'numeric'})}</small>
                </Card.Footer>
            </Card>
        ))}
    </CardGroup>
    */}

                {/*
    <div className={'d-none'}>
        <div>
            {t('last updated')}: {format(generationDate, 'PPpp')}
        </div>
        <div>
            {t('last updated')}: {formatRelative(generationDate, Date.now())}
        </div>
    </div>
        */}

                <Tabs defaultActiveKey="streaming" id="uncontrolled-tab-example" className="mb-3" justify>
                    <Tab eventKey="streaming" title={capitalizeFirstLetter(t('popular movies')) /* Streaming */}>
                        {getCardList({ items: items1 })}
                    </Tab>
                    <Tab eventKey="tv" title={capitalizeFirstLetter(t('popular TV shows')) /* on TV */}>
                        {getCardList({ items: items2 })}
                    </Tab>
                    {/* TODO * /
    <Tab eventKey="rent" title={t('for rent')}>
        {getCardList({items: items3})}
    </Tab>
    <Tab eventKey="cinema" title={t('in cinemas')}>
        {getCardList({items: items4})}
    </Tab>
    /**/}
                </Tabs>

            </Container>);
}
interface PageProps {
    watchProviders: WatchProvider[];
    items1: any[];
    items2: any[];
    items3: any[];
    items4: any[];
    generationDate: number;
}
// This function gets called at build time
// export async function getStaticProps(context) {
const getStaticProps: GetStaticProps<PageProps> = async (context) => {
    const { locale } = context;
    // const locale = 'hu-HU';
    const movieDb = new MovieDb(publicRuntimeConfig.movieDbApiKey, publicRuntimeConfig.movieDbBaseUrl, publicRuntimeConfig.movieDbRequestsPerSecondLimit);
    let watchProviders: WatchProvider[] = [];
    let items1 = [];
    let items2 = [];
    let items3 = [];
    let items4 = [];
    try {
        // TODO: use this later
        // watchProviders = (await movieDb.movieWatchProviderList({ // 20 piece
        //     language: locale,
        // })).results;
        /*
        const res = await moviedb.searchMovie({
            query: 'alien',
            language: 'hu',
        })
        */
        const res1 = await movieDb.moviePopular({
            language: locale,
        });
        const res1_2 = await movieDb.moviePopular({
            language: locale,
            page: 2,
        });
        const res1_3 = await movieDb.moviePopular({
            language: locale,
            page: 3,
        });
        items1 = res1.results.concat(res1_2.results).concat(res1_3.results);
        const res2 = await movieDb.tvPopular({
            language: locale,
        });
        const res2_2 = await movieDb.tvPopular({
            language: locale,
            page: 2,
        });
        const res2_3 = await movieDb.tvPopular({
            language: locale,
            page: 3,
        });
        items2 = res2.results.concat(res2_2.results).concat(res2_3.results);
        // to fill every row in every layout, the items should be divisible by 12
        // items1 = items1.slice(0, 36)
        // items2 = items2.slice(0, 36)
    }
    catch (e) {
        console.log(e);
    }
    return {
        props: {
            watchProviders: watchProviders,
            items1: items1,
            items2: items2,
            items3: items3,
            items4: items4,
            generationDate: Date.now(),
        },
        revalidate: 30 * 60, // 30 minutes
    };
};

    async function __Next_Translate__getStaticProps__18c042eefa0__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18c042eefa0__ as getStaticProps }
  