import {useEffect, useState} from 'react'
import Image, {ImageProps} from 'next/image';

/*
export const ImageWithFallback = ({src, ...rest}: ImageProps) => {
    const [imgSrc, setImgSrc] = useState(src)

    useEffect(() => {
        setImgSrc(src)
    }, [src])

    return (
        <Image
            {...rest}
            src={imgSrc ? imgSrc : '/placeholder.png'}
            onError={() => {
                setImgSrc('/placeholder.png')
            }}
        />
    )
}
*/

// const defaultFallbackSrc = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-8 -8 40 40' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-image'%3E%3Crect x='3' y='3' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Ccircle cx='8.5' cy='8.5' r='1.5'%3E%3C/circle%3E%3Cpolyline points='21 15 16 10 5 21'%3E%3C/polyline%3E%3C/svg%3E";
const defaultFallbackSrc = '/placeholder.svg'; // i saved it

const imageWithFallbackClass = 'imageWithFallback'

interface ImageWithFallbackProps extends ImageProps {
    fallbackSrc?: string,
    alt: string, // force alt attribute; below i have to handle it explicitly because of eslint...
}

// mindkettő jó, kérdés melyiket használjam és miért

// export function ImageWithFallback(props: ImageWithFallbackProps): JSX.Element {
export const ImageWithFallback = (props: ImageWithFallbackProps): JSX.Element => {

    const {src, fallbackSrc, alt, className, ...rest} = props;
    const [imgSrc, setImgSrc] = useState(src);

    // this is needed to cause re-render on src change
    useEffect(() => {
        setImgSrc(src);
    }, [src]);

    const newClassName = className ? (className.includes(imageWithFallbackClass) ? className : `${className} ${imageWithFallbackClass}`) : imageWithFallbackClass

    const isFixedImageBoxRatio = rest.width && rest.height && rest.fill === undefined;
    if (isFixedImageBoxRatio) {
        rest.style = {
            ...rest.style,
            width: '100%',
            height: '100%',
        }
    }

    const imageElement = <Image
        {...rest}
        src={imgSrc}
        alt={alt}
        className={newClassName}
        onLoad={(e) => {
            if (e.currentTarget.naturalWidth === 0) {
                // Broken image
                setImgSrc(fallbackSrc);
            }
        }}
        onError={() => {
            setImgSrc(fallbackSrc ?? defaultFallbackSrc);
        }}
    />

    if (isFixedImageBoxRatio) {
        return (
            <div style={{
                // @ts-ignore
                aspectRatio: rest.width / rest.height,
            }}>
                {imageElement}
            </div>
        )
    }

    return (
        imageElement
    );
};
