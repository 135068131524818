import Card from 'react-bootstrap/Card';
import Link from 'next/link';
import {FunctionComponent} from 'react';
import {ImageWithFallback} from '../ImageWithFallback';
import {ImageProps} from 'next/image';

// use luxon instead of date-fns
// import {format} from 'date-fns'

// import format from "date-fns/fp/format"

import { DateTime } from 'luxon';

interface Props {
    url: string;
    imageUrl: string;
    title: string;
    dateString?: string;
    imageLoading?: 'lazy' | 'eager';
    imageLoadingPriority?: boolean;
    quality?: ImageProps['quality'],
    unoptimized?: boolean;
}

const Media: FunctionComponent<Props> = (
    {
        url,
        imageUrl,
        title,
        dateString,
        imageLoading,
        imageLoadingPriority,
        quality,
        unoptimized,
    },
) => {
    return (
        <Link href={url} style={{textDecoration: 'none'}}>
            <Card className="h-100 myCard">
                {/*<div style={{ // ez azért kell, mert a debuggerben panaszkodott*/}
                {/*    width: '300',*/}
                {/*    height: '450',*/}
                {/*}}>*/}
                <ImageWithFallback
                    src={imageUrl}
                    alt={title}
                    width={300}
                    height={450}
                    quality={quality}
                    loading={imageLoading}
                    priority={imageLoadingPriority}
                    unoptimized={unoptimized}
                    referrerPolicy={'no-referrer'}
                />
                {/*</div>*/}

                {/** /
                 <Card.Img variant="top" src={imageUrl}/>
                 /**/}
                {/*
                        <Card.Body>
                            <Image
                                src={movieDbConfig.images.secure_base_url + movieDbConfig.images.poster_sizes[2] + movie.poster_path}
                                alt="Picture of the author"
                                width={500}
                                layout='fill'
                            />
                        </Card.Body>
                        */}
                <Card.Body>
                    <Card.Title><span className={'fs-5'}>{title}</span></Card.Title>
                </Card.Body>
                {/*
                        <Card.Body>
                            <ProgressBar>
                                <ProgressBar variant="success" now={movie.vote_average * 10}/>
                                <ProgressBar variant="danger" now={100 - movie.vote_average * 10}/>
                            </ProgressBar>
                        </Card.Body>
                        */}
                {/*
                        <Card.Footer>
                            <small className="text-dark">{DateTime.fromISO('release_date' in movie ? movie.release_date : movie.first_air_date).toLocaleString({
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric'
                            })}</small>
                        </Card.Footer>
                        */}
                <Card.Footer>
                    <small className="text-dark">
                        {dateString ? DateTime.fromISO(dateString).toLocaleString(DateTime.DATE_FULL) : '—'}
                    </small>
                </Card.Footer>
            </Card>
        </Link>
    )
}

export default Media
